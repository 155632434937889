// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// routes
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function NavBallCharts() {

  return (
    <Stack
      sx={{
        px: 5,
        py: 5,
        textAlign: 'center',
      }}
    >

      <Button variant="soft" color="success" href={paths.ballcharts} target="_blank" rel="noopener">
        <Stack alignItems="center">
              Powered by:
          <Box
            component="img"
            alt="logo"
            src="https://ballcharts.com/images/ballcharts_oval2.png"
            sx={{ height: 40, my: 2, borderRadius: 1 }}
          />
              Team & League Websites
        </Stack>
      </Button>
    </Stack>
  );
}
