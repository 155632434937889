import PropTypes from 'prop-types';
import { paths } from 'src/routes/paths';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTeam } from 'src/hooks/use-team';
import { useAuthContext } from 'src/auth/hooks';
import { RouterLink } from 'src/routes/components';

// components
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import FlagIcon from 'src/components/ballcharts/flag';
import Iconify from 'src/components/iconify';

//
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  NotificationsPopover,
} from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const { user } = useAuthContext();
  const team = useTeam();

  let teamname1 = '';
  let teamname2 = '';

  if (team?.teamname){
    const teamnameArray = team?.teamname?.split('|');
    teamname1 = teamnameArray[0] || '';
    teamname2 = teamnameArray[1] || '';
  }

  let team_url = `https://ballcharts.com/${team?.team}`;
  let team_url_show = `ballcharts.com/${team?.team}`;
  if (team?.domain){
    team_url = `http://${team?.domain}`;
    team_url_show = team?.domain;
  }
  let sportIcon = team.sport;
  if (sportIcon === 'hockey') {
    sportIcon = "ice-hockey";
  } else if (sportIcon === 'football'){
    sportIcon = "american-football";
  } else if (sportIcon === 'pool'){
    sportIcon = "pool-8-ball";
  } else if (sportIcon === 'soccer'){
    sportIcon = "soccer-ball";
  }

  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const mdUp = useResponsive('up', 'md');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const searchParams = new URLSearchParams({
    team: team.team,
    returnTo: window.location.pathname.replace(process.env.PUBLIC_URL, ""), // remove the /t
  }).toString();

  const loginPath = `${paths.auth.ballcharts.login}?${searchParams}`;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5, width: 50,
    height: "90%",}} />}
      <Stack
        flexGrow={1}
        direction="column"
        spacing={0}
      >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <Iconify icon="eva:menu-fill" width={30} />
        </IconButton>
      )}
      {mdUp && (
        <Typography sx={{ mt: -1, mr: 2}}>
          <Iconify icon={`noto:${sportIcon}`} sx={{ width: 30, height: 30 }} />
        </Typography>
      )}
      <Typography variant={mdUp ? "h3" : "h5"} sx={{ mb: mdUp ? 2 : 0, color: `#${team.teamnamecolor}`, textShadow: `2px 2px 0px #${team.teamnamebackcolor}` }}>
        {teamname1}
      </Typography>
      {teamname2 && mdUp && (
        <Typography variant="h5" sx={{ mb: 1, ml: 2, color: 'text.secondary' }}>
        {teamname2}
        </Typography>
      )}
      </Stack>
      {mdUp && (
      <Typography sx={mdUp && {ml: 6, mt: -3}} >
        <Link sx={{color: 'text.secondary'}} href={team_url}>{team_url_show}</Link>
      </Typography>
      )}
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {mdUp && (<Searchbar />)}
        
        {mdUp && (<FlagIcon flag={team.countryFlag}/>)}

        {(user && team?.MemberConfirmed) && <NotificationsPopover />}

        <SettingsButton />

        { user ? <AccountPopover /> 
        :(
          <Button component={RouterLink} href={loginPath} variant="outlined">
            Login
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        '@media print': {
          display: 'none'
        }, 
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
